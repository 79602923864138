import { ContentSource, View } from 'datalayer/store'
import { RemoveActions, removeActions } from './remove'
import { UploadActions, uploadActions } from './upload'

import { deleteParam } from 'utils/generalUtils'

export type Actions = {
  actions: {
    upload: UploadActions
    remove: RemoveActions
  }
  setDiscard: (bool: boolean) => void
  discardPosts: () => void
  cancelDiscard: () => void
  setShouldClose: (bool: boolean) => void
  setInstagram: (bool: boolean) => void
  setMode: (mode: string) => void
  setOrigin: (origin: string) => void
  setSource: (source) => void
  setError: (error: string, customErrorMessage?: string) => void
  setRetry: (value: number) => void
  setContentSource: (source: ContentSource) => void
  setView: (view: View) => void
  decrementPostIndex: () => void
  incrementPostIndex: () => void
  setPosts: (posts: Array<any>) => void
  removePost: (id: string) => void
  setPostIndex: (index: number) => void
  setAuthType: (type: 'new-member' | 'existing-member') => void
  closeApp: () => void
  clear: () => void
}

export const initActions = (set, get): Actions => {
  return {
    actions: {
      upload: uploadActions(set, get),
      remove: removeActions(set, get)
    },
    setDiscard: (bool) => {
      set((state) => {
        state.discard = bool
      })
    },
    discardPosts: () => {
      const { closeApp, shouldClose } = get()
      set((state) => {
        state.posts = []
        state.discard = false
        state.postIndex = 0
        state.view = 'upload-options'
        state.error = undefined
        state.contentSource = undefined
        state.instagramPosts = []
        state.isInstagram = false
        state.authType = undefined
      })
      deleteParam('accessToken')
      if (shouldClose) {
        closeApp()
      }
    },
    cancelDiscard: () => {
      set((state) => {
        state.discard = false
        state.shouldClose = false
      })
    },
    setShouldClose: (bool) => {
      set((state) => {
        state.shouldClose = bool
      })
    },
    setMode: (mode) => {
      set((state) => {
        state.mode = mode
      })
    },
    setOrigin: (origin) => {
      set((state) => {
        state.origin = origin
      })
    },
    setSource: (source) => {
      set((state) => {
        state.source = source
      })
    },
    setError: (error, customErrorMessage) => {
      set((state) => {
        state.error = error
        state.customErrorMessage = customErrorMessage
      })
    },
    setRetry: (value) => {
      set((state) => {
        state.retry = value
      })
    },
    setContentSource: (source) => {
      set((state) => {
        state.contentSource = source
      })
    },
    setView: (view: View) => {
      set((state) => {
        state.view = view
      })
    },
    setInstagram: (bool: Boolean) => {
      set((state) => {
        state.isInstagram = bool
      })
    },
    decrementPostIndex: () => {
      set((state) => {
        if (state.postIndex === 0) {
          return
        }
        state.postIndex = state.postIndex - 1
      })
    },
    incrementPostIndex: () => {
      set((state) => {
        if (state.postIndex === state.posts?.length - 1) {
          return
        }
        state.postIndex = state.postIndex + 1
      })
    },
    setPosts: (posts) => {
      set((state) => {
        state.posts = posts
      })
    },
    removePost: (removePost) => {
      set((state) => {
        state.posts = state.posts.filter(
          (post) =>
            post?.preview !== removePost &&
            post?.instagramMediaUrl !== removePost
        )
        if (state.postIndex === state.posts?.length) {
          state.postIndex = state.postIndex - 1
        }
      })
    },
    setPostIndex: (index) => {
      set((state) => {
        state.postIndex = index
      })
    },
    setAuthType: (type) => {
      set((state) => {
        state.authType = type
      })
    },

    closeApp: () => {
      const { origin, company, mode } = get()

      if (origin) {
        window.close()
        window.parent.postMessage(JSON.stringify({ close: true }), '*')
        return
      }

      if (!origin && company?.domain) {
        window.location.href = company?.domain
        return
      }

      window.parent.postMessage(JSON.stringify({ close: true }), '*')

      if (window.innerWidth < 768 && origin && mode === 'page') {
        window.location.href = `https://${origin}`
      }
    },

    clear: () => {
      set((state) => {
        state.posts = []
        state.discard = false
        state.postIndex = 0
        state.view = 'upload-options'
        state.error = undefined
        state.contentSource = undefined
        state.retry = 0
        state.failed = []
        state.postIndex = 0
        state.gridPosts = []
        state.instagramPosts = []
        state.isInstagram = false
        state.authType = undefined
        state.pendingUploadId = ''
      })
      deleteParam('accessToken')
    }
  }
}
