import * as sentry from '@sentry/nextjs'

import { dev } from 'config'
import { getGraphqlError } from './generalUtils'
import { useStore } from 'datalayer/store'

type ReportErrorOptions = {
  showError?: boolean
  key?: string
  message?: string
}

export const reportError = (
  error,
  { showError = true, key = 'custom', message }: ReportErrorOptions = {}
) => {
  if (!dev) {
    sentry.captureException(error)
  }

  const serverMessage = getGraphqlError(error)
  const errorMessage = serverMessage || message || error.message

  if (showError) {
    const { setError } = useStore.getState()

    setError(key, errorMessage)
  }

  console.error(errorMessage)
}
