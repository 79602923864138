import React, { useEffect } from 'react'

import Grid from './grid'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'

export default function GridBackground({ ...props }) {
  const gridPosts = useStore((state) => state.gridPosts)
  const mode = useStore((state) => state.mode)

  useEffect(() => {
    if (window !== undefined) {
      new Grid(document.querySelector('.grid'))
    }
  }, [gridPosts])

  if (!gridPosts.length || mode !== 'page') return null

  return (
    <Style
      key="grid"
      className="grid"
      initial="hidden"
      animate="show"
      exit="hidden"
      variants={gridAnimation}
    >
      {gridPosts.map((post, index) => (
        <div key={index} className={`grid-item pos-${index}`}>
          <div
            className="grid-item-img"
            style={{
              backgroundImage: `url(${post?.media?.location}?class=400)`
            }}
          ></div>
        </div>
      ))}
    </Style>
  )
}

export const Style = styled(motion.div)`
  pointer-events: none;
  position: absolute;
  width: 110%;
  height: 110%;
  top: -5%;
  left: -5%;
  grid-template-columns: repeat(50, 2%);
  grid-template-rows: repeat(50, 2%);
  display: none;

  ${media.greaterThan('medium')`
    display: grid;
  `}

  .grid-item {
    position: relative;
  }

  .grid-item-img {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: var(--border-radius);
  }

  .pos-0 {
    grid-area: 10 / 1 / 26 / 7;
  }

  .pos-1 {
    grid-area: 1 / 18 / 9 / 27;
  }

  .pos-2 {
    grid-area: 1 / 36 / 14 / 42;
  }

  .pos-3 {
    grid-area: 13 / 11 / 32 / 18;
  }

  .pos-4 {
    grid-area: 17 / 32 / 32 / 38;
  }

  .pos-5 {
    grid-area: 20 / 46 / 28 / 51;
  }

  .pos-6 {
    grid-area: 43 / 1 / 51 / 10;
  }

  .pos-7 {
    grid-area: 38 / 14 / 46 / 22;
  }

  .pos-8 {
    grid-area: 40 / 26 / 51 / 32;
  }

  .pos-9 {
    grid-area: 37 / 39 / 48 / 47;
  }
`

export const gridAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: 'easeInOut', duration: 1 }
  },
  show: { opacity: 1, transition: { ease: 'easeInOut', duration: 0 } }
}
