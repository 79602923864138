import { GraphQLClient } from 'graphql-request'
import { graphqlApiEndpoint } from 'config'
import { reportError } from 'utils/error'

class CustomGraphQLClient extends GraphQLClient {
  controller
  timeout = 10000

  constructor(endpoint, opts, controller) {
    super(endpoint, { ...opts, signal: controller.signal })

    this.controller = controller

    if (opts.timeout) {
      this.timeout = opts.timeout
    }
  }

  async request(doc, variables, headers = null) {
    const id = setTimeout(() => {
      reportError(new Error(`Request timed out after 60 seconds`), {
        showError: false
      })

      this.controller.abort()
    }, this.timeout)

    try {
      const res = await super.request(doc, variables, headers)

      clearTimeout(id)

      return res
    } catch (error) {
      clearTimeout(id)

      throw error
    }
  }
}

const getClient = () => {
  const controller = new AbortController()

  return new CustomGraphQLClient(
    graphqlApiEndpoint,
    {
      credentials: 'include',
      timeout: 60000
    },
    controller
  )
}

export { getClient }
