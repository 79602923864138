import 'datalayer'

import App from 'next/app'
import Background from 'components/Background/Background'
import { GlobalStyle } from 'ui/global'
import React from 'react'
import Theme from 'components/Theme/Theme'

export default class extends App {
  render() {
    const { Component, pageProps, router } = this.props

    return (
      <>
        <GlobalStyle />
        <Theme>
          <Background />
          <Component {...pageProps} key={router.route} />
        </Theme>
      </>
    )
  }
}
