import { cevoidApiUrl } from 'config'
import { getClient } from './client'
import { gql } from 'graphql-request'

const GET_INSTAGRAM_USER = gql`
  query getInstagramUser($token: String!) {
    getInstagramUser(token: $token) {
      id
      username
      type
    }
  }
`

const GET_INSTAGRAM_MEDIA = gql`
  query getInstagramMedia($token: String!, $id: String!) {
    getInstagramMedia(token: $token, id: $id) {
      media {
        id
        media_url
        media_type
        caption
        permalink
        thumbnail_url
        children {
          data {
            id
            media_url
            media_type
          }
        }
      }
      next
    }
  }
`

const GET_MORE_INSTAGRAM_MEDIA = gql`
  query getMoreInstagramMedia($next: String!) {
    getMoreInstagramMedia(next: $next) {
      media {
        id
        media_url
        media_type
        caption
        permalink
        thumbnail_url
        children {
          data {
            id
            media_url
            media_type
          }
        }
      }
      next
    }
  }
`

const GET_POSTS_BY_INSTAGRAM_USER = gql`
  query getPostsByInstagramUser($id: ID!) {
    getPostsByInstagramUser(id: $id) {
      _id
      type
      media {
        location
      }
    }
  }
`

const REMOVE_INSTAGRAM_POST = gql`
  mutation removeInstagramPostAsUploader($id: ID!, $instagramUserId: ID!) {
    removeInstagramPostAsUploader(id: $id, instagramUserId: $instagramUserId)
  }
`

const getInstagramUser = async (token) => {
  const data = await getClient().request(GET_INSTAGRAM_USER, { token })

  return data.getInstagramUser
}

export type InstagramMedia = {
  id: string
  media_url: string
  thumbnail_url?: string
  media_type: 'VIDEO' | 'IMAGE' | 'CAROUSEL_ALBUM'
  caption: string
  permalink: string
  children?: {
    data: {
      id: string
      media_url: string
      media_type: 'IMAGE' | 'VIDEO'
    }[]
  }
}

type InstagramMediaResult = {
  next?: string
  media: InstagramMedia[]
}

const getInstagramMedia = async ({
  id,
  token
}): Promise<InstagramMediaResult> => {
  const data = await getClient().request(GET_INSTAGRAM_MEDIA, { id, token })

  return data.getInstagramMedia
}

const getMoreInstagramMedia = async (next): Promise<InstagramMediaResult> => {
  const data = await getClient().request(GET_MORE_INSTAGRAM_MEDIA, { next })

  return data.getMoreInstagramMedia
}

const getPostsByInstagramUser = async (id) => {
  const data = await getClient().request(GET_POSTS_BY_INSTAGRAM_USER, { id })

  return data.getPostsByInstagramUser
}

const removeInstagramPostAsUploader = async ({ id, instagramUserId }) => {
  const data = await getClient().request(REMOVE_INSTAGRAM_POST, {
    id,
    instagramUserId
  })

  return data.removeInstagramPostAsUploader
}

const connectInstagram = (redirect) => {
  return redirect
    ? `${cevoidApiUrl}/social/auth/instagram/one_time/access_token?redirect=${redirect}`
    : `${cevoidApiUrl}/social/auth/instagram/one_time/access_token`
}

export {
  connectInstagram,
  getInstagramUser,
  getInstagramMedia,
  getMoreInstagramMedia,
  getPostsByInstagramUser,
  removeInstagramPostAsUploader
}
