export const preloadImage = async (url: string) => {
  const res = []

  await new Promise<void>((resolve) => {
    const img = new Image()
    img.src = url

    img.onload = () => {
      res.push(url)
      resolve()
    }
  })

  return res
}

export const getQueryParam = (name: string) => {
  try {
    const parser = new URLSearchParams(window.location.search)

    return parser.get(name)
  } catch (error) {
    console.error(error)
  }
}

export const deleteParam = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search)

  if (!urlParams.get(key)) {
    return
  }

  urlParams.delete(key)

  const newParams = urlParams.toString()

  const newUrl = `${window.location.href.split('?')[0]}${
    newParams?.length > 0 ? `?${newParams}` : '   '
  }`

  window.history.pushState({}, '', newUrl)

  return newUrl
}

export const hasQueryParams = () => {
  const split = window.location.href.split('?')

  return split.length > 1 && split[1] !== ''
}

export const getQueryString = (params: {}) => {
  return Object.keys(params)
    .map((key) => {
      if (!!params[key]) {
        return `${key}=${params[key]}`
      }
    })
    .filter((notNull) => notNull)
    .join('&')
}

export const getGraphqlError = (error) => {
  const messages = error?.response?.errors?.map((e) => e.message)

  if (!messages) {
    return error.message
  }

  if (messages?.length === 1) {
    return messages[0]
  }

  return messages
}

export const copyObject = (item) => JSON.parse(JSON.stringify(item))

export const shadeColor = (color: string, amount: number) => {
  return (
    '#' +
    color
      ?.replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  )
}

export const lightOrDark = (color) => {
  if (!color) {
    return undefined
  }

  let r, g, b, hsp

  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  if (hsp > 210) {
    return '#000'
  } else {
    return '#fff'
  }
}

export const slugify = (text: string) => {
  if (!text) {
    return text
  }

  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const camelize = (str) => {
  if (!str) {
    return
  }

  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase())
}

export const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text)
}

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export const getCroppedImg = async (imageSrc: string, pixelCrop) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  // set canvas size to match the bounding box
  canvas.width = image.width
  canvas.height = image.height

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(file)
    }, 'image/jpeg')
  })
}

export function validateVideoLength(file, maxDurationInSeconds: number) {
  return new Promise((resolve) => {
    var video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src)

      if (video.duration > maxDurationInSeconds) {
        return resolve(false)
      }

      return resolve(true)
    }

    video.src = URL.createObjectURL(file)
  })
}

export function getPostType(mimeType: string) {
  if (!mimeType) {
    return 'unknown'
  }

  return mimeType.split('/')[0].toUpperCase()
}

export function isNullish(value: any) {
  return value === null || value === undefined
}
