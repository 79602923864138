import { createGlobalStyle } from 'styled-components'
import media from 'styled-media-query'

export const GlobalStyle = createGlobalStyle`
    :root {    
    --border-radius: 12px;
    
    --button-text-light: #FFFFFF;
    --button-text-dark: #000000;
    
    --background: #FFFFFF;
    --border: #DDDDDD;

    --light: #F7F7F7;
    --light-hover: #F0F0F0;
    --light-active: #ebebeb;

    --text: #333;
    --text-active: #000;
    --text-opacity: #666666;

    --negative: #EB4563;

    --font-lg: 30px;
    --font-md: 22px;
    --font-sm: 20px;
    --font-text: 14px;
    --font-xs: 12px;

    --spacing-xl: 70px;
    --spacing-lg: 50px;
    --spacing-md: 30px;
    --spacing-sm: 15px;
    --spacing-xs: 5px;
    }

    * {
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        touch-action: pan-x pan-y;
    }


    html {
        line-height: 1.2em;
        min-height: 100%;
        height: -webkit-fill-available;
    }
    
    body {
        margin: 0px;
        color: var(--text);
        text-rendering: optimizelegibility;
        user-select: none;
        text-size-adjust: none;
        letter-spacing: -0.01em;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        height: 100dvh;
    }

    html, body {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    #root, body {
        height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 400;
        color: var(--text);
        letter-spacing: -0.03em;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
    
    h1 {
        font-size: var(--font-md);
        line-height: 1.2em;
    }

    h2 {
        font-size: var(--font-sm);
        line-height: 1.1em;

        ${media.greaterThan('medium')`
            font-size: var(--font-md);
        `}
    }

    h3 {
        font-size: var(--font-text);
        line-height: 1.2em;

        ${media.greaterThan('medium')`
            font-size: var(--font-sm);
        `}
    }

    h4 {
        font-size: var(--font-text);
        line-height: 1.5em;
    }

    p {
        font-size: var(--font-text);
        line-height: 1.4em;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }

    a {
        text-decoration: none;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.2;
        margin: 0;
        appearance: none;
    }
    
    button,
    input {
        overflow: visible;
    }
    
    button,
    select {
        text-transform: none;
    }

    textarea {
        overflow: auto;
    }
  
    [type='checkbox'],
    [type='radio'] {
        box-sizing: border-box;
        padding: 0;
    }
    
    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
        height: auto;
    }
    
    [type='search'] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
    }
    
    [type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    
    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
    }

    @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .rotating {
    -webkit-animation: rotating 0.4s linear infinite;
    -moz-animation: rotating 0.4s linear infinite;
    -ms-animation: rotating 0.4s linear infinite;
    -o-animation: rotating 0.4s linear infinite;
    animation: rotating 0.4s linear infinite;
  }

`
