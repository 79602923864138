import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { Close } from 'ui/icons'
import GridBackground from './GridBackground'
import React from 'react'
import media from 'styled-media-query'
import { useStore } from 'datalayer/store'

export default function Background({ ...props }) {
  const view = useStore((state) => state.view)
  const mode = useStore((state) => state.mode)
  const origin = useStore((state) => state.origin)
  const setDiscard = useStore((state) => state.setDiscard)
  const setShouldClose = useStore((state) => state.setShouldClose)
  const closeApp = useStore((state) => state.closeApp)

  const closeUpload = () => {
    if (view === 'edit-posts' || view === 'new-member') {
      setDiscard(true)
      setShouldClose(true)
      return
    }
    closeApp()
  }

  return (
    <Style
      mode={mode}
      initial={'hidden'}
      animate={'show'}
      exit={'exit'}
      onClick={() => (origin || mode === 'modal' ? closeUpload() : null)}
      {...props}
    >
      <AnimatePresence>
        {view === 'upload-options' && mode === 'page' && <GridBackground />}
      </AnimatePresence>
      {(origin || mode === 'modal') && (
        <motion.div className="close-button" onClick={() => closeUpload()}>
          <Close />
        </motion.div>
      )}
    </Style>
  )
}

export const Style = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;

  ${(props) =>
    props.mode === 'page' &&
    css`
      background: #fff !important;
    `}

  ${media.lessThan('medium')`
    background: #fff !important;
  `}

  .close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #fff;
    height: 46px;
    width: 46px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    display: none;
    box-shadow: 0px 2px 10px -2px rgb(0 0 0 / 10%);
    border: 1px solid rgb(221 221 221 / 50%);

    svg {
      height: 26px;
      width: 26px;
    }

    ${media.greaterThan('medium')`
      display: flex;
    `}
  }
`
