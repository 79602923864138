import React, { useEffect } from 'react'
import { lightOrDark, shadeColor } from 'utils/generalUtils'

import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'

export const Style = styled(motion.div)``

export default function Theme({ children, ...props }) {
  const company = useStore((state) => state.company)

  const color =
    !company?.accentColor || company?.accentColor === '#ffffff'
      ? '#000000'
      : company?.accentColor

  useEffect(() => {
    document.documentElement.setAttribute(
      'style',
      `--primary: ${color}; 
      --primary-hover: ${shadeColor(
        color,
        lightOrDark(color) === '#000' ? -10 : 10
      )}; 
      --primary-active: ${shadeColor(
        color,
        lightOrDark(color) === '#000' ? -20 : 20
      )};
      --primary-text: ${lightOrDark(color)};`
    )
  }, [color])

  return <Style {...props}>{children}</Style>
}
