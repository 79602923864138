import { gsap } from 'gsap'

const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b

const calcWinsize = () => {
  if (typeof window !== 'undefined') {
    return { width: window.innerWidth, height: window.innerHeight }
  }
  return
}

const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)

// Gets the mouse position
const getMousePos = (e) => {
  return {
    x: e.clientX,
    y: e.clientY
  }
}

// Calculate the viewport size
let winsize = calcWinsize()
if (typeof window !== 'undefined') {
  window.addEventListener('resize', () => (winsize = calcWinsize()))
}

// Track the mouse position
let mousepos = { x: winsize?.width / 2, y: winsize?.height / 2 }
if (typeof window !== 'undefined') {
  window.addEventListener('mousemove', (ev) => (mousepos = getMousePos(ev)))
}

class GridItem {
  DOM: { el: any }

  constructor(el) {
    this.DOM = { el: el }
    this.move()
  }

  // Move the items when moving the cursor
  move() {
    // amounts to move in each axis
    let translationVals = { tx: 0, ty: 0 }
    // get random start and end movement boundaries
    const xstart = getRandomNumber(15, 60)
    const ystart = getRandomNumber(15, 60)

    // infinite loop
    const render = () => {
      // Calculate the amount to move.
      // Using linear interpolation to smooth things out.
      // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
      translationVals.tx = lerp(
        translationVals.tx,
        map(mousepos.x, 0, winsize.width, -xstart, xstart),
        0.07
      )
      translationVals.ty = lerp(
        translationVals.ty,
        map(mousepos.y, 0, winsize.height, -ystart, ystart),
        0.07
      )

      gsap.set(this.DOM.el, { x: translationVals.tx, y: translationVals.ty })
      requestAnimationFrame(render)
    }
    requestAnimationFrame(render)
  }
}

export default class Grid {
  DOM: { el: any }
  gridItems: any
  items: any

  constructor(el) {
    this.DOM = { el: el }
    this.gridItems = []
    if (this.DOM.el) {
      this.items = [...this.DOM.el.querySelectorAll('.grid-item')]
      this.items.forEach((item) => this.gridItems.push(new GridItem(item)))

      this.showItems()
    }
  }
  // Initial animation to scale up and fade in the items
  showItems() {
    gsap
      .timeline()
      .set(this.items, { scale: 0.7, opacity: 0 }, 0)
      .to(
        this.items,
        {
          duration: 2,
          ease: 'Expo.easeOut',
          scale: 1,
          stagger: { amount: 0.6, grid: 'auto', from: 'center' }
        },
        0
      )
      .to(
        this.items,
        {
          duration: 3,
          ease: 'Power1.easeOut',
          opacity: 1,
          stagger: { amount: 0.6, grid: 'auto', from: 'center' }
        },
        0
      )
  }
}
